
import { defineComponent, ref, computed, watchEffect, unref } from 'vue'
import { useToggle } from '../../composables/useToggle'

export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup (props) {
    const seconds = 8

    const currentIndex = ref(0)

    const { isActive, toggle } = useToggle(true)

    const currentItem = computed(() => props.items[unref(currentIndex)])

    const pages = computed(() => props.items.length)

    const setActive = (index: number) => {
      currentIndex.value = index
    }

    const interval = ref(0)

    watchEffect(() => {
      if (unref(isActive)) {
        interval.value = setInterval(() => {
          if (!unref(isActive)) {
            return
          }

          if (unref(currentIndex) === unref(pages) - 1) {
            currentIndex.value = 0
          } else {
            currentIndex.value++
          }
        }, seconds * 1000)
      } else {
        clearInterval(unref(interval))
      }
    })

    return {
      currentIndex,
      currentItem,
      pages,
      setActive,
      toggle
    }
  }
})
