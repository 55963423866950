<template>
  <div @mouseover="toggle" class="p-2">
    <div class="font-normal text-lg px-8 text-white" @mouseover.stop>{{ currentItem }}</div>
    <div class="pagination" @mouseover.stop>
      <button
        v-for="(page, index) in pages"
        :key="`page-${page}`"
        :class="{ 'active': index === currentIndex }"
        @click="setActive(index)"
        class="pagination-button"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watchEffect, unref } from 'vue'
import { useToggle } from '../../composables/useToggle'

export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup (props) {
    const seconds = 8

    const currentIndex = ref(0)

    const { isActive, toggle } = useToggle(true)

    const currentItem = computed(() => props.items[unref(currentIndex)])

    const pages = computed(() => props.items.length)

    const setActive = (index: number) => {
      currentIndex.value = index
    }

    const interval = ref(0)

    watchEffect(() => {
      if (unref(isActive)) {
        interval.value = setInterval(() => {
          if (!unref(isActive)) {
            return
          }

          if (unref(currentIndex) === unref(pages) - 1) {
            currentIndex.value = 0
          } else {
            currentIndex.value++
          }
        }, seconds * 1000)
      } else {
        clearInterval(unref(interval))
      }
    })

    return {
      currentIndex,
      currentItem,
      pages,
      setActive,
      toggle
    }
  }
})
</script>

<style scoped>
.pagination-button {
  outline: none;
  appearance: none;
  border: none;
  background: rgba(250, 250, 250, 0.5);
  border-radius: 8px;
  width: 8px;
  height: 8px;
  margin: 0 4px;
}

.pagination-button.active {
  width: 24px;
  background: #FAFAFA;
}
</style>
