<template>
  <div class="md:flex min-w-full h-screen">
    <div class="sidebar hidden max-h-screen md:flex flex-col items-center text-white w-2/5">
      <div class="flex flex-1 flex-col justify-around items-center">
        <div class="text-center">
          <img src="@/assets/logo.svg" alt="manycontent" class="w-64"/>
        </div>
        <div class="text-center">
          <img src="@/assets/manyrobot.svg" alt="manyrobot" class="transform rotate-6"/>
        </div>
        <div class="flex flex-col justify-end text-center">
          <span class="font-sora font-bold text-3xl block text-white">
            {{ $t('page.auth.sentence.find_it_out') }}
          </span>
          <discover-carousel :items="discoverItems" />
        </div>
      </div>
    </div>

    <div class="flex flex-1 flex-col justify-start items-center min-h-screen w-full py-8 overflow-y-auto">
      <div class="md:hidden flex justify-center items-center w-full mt-8">
        <img src="@/assets/logo_colorida.png" class="w-64" />
      </div>

      <div class="flex flex-grow justify-center min-h-full px-4 w-full">
        <slot/>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import DiscoverCarousel from './DiscoverCarousel.vue'

export default defineComponent({
  components: {
    DiscoverCarousel
  },
  setup () {
    const { t } = useI18n()
    
    const discoverItems = computed(() => [
      t('page.auth.sentence.find_it_out_1'),
      t('page.auth.sentence.find_it_out_2'),
      t('page.auth.sentence.find_it_out_3'),
      t('page.auth.sentence.find_it_out_4'),
      t('page.auth.sentence.find_it_out_5'),
    ])

    return {
      discoverItems
    }
  }
})
</script>

<style scoped>
.sidebar {
  background: url('~@/assets/header_bg.svg') 0% 0% / cover, linear-gradient(326.68deg, #00E1CA 3.78%, #5640FB 92.7%);
}
</style>
